import * as React from "react";
import styled from "@emotion/styled";
import { Link } from 'gatsby';

import { DefaultLayout } from '../components/layouts/default-layout';
import HomePageEditor from "../components/pages/home-page/home-page-editor";
import GraphicalArtsBlock from "../components/pages/home-page/graphical-arts-block";
import HelloBlock from "../components/pages/home-page/hello-block";
import GamedevBlock from "../components/pages/home-page/gamedev-block";
import PhotographyBlock from "../components/pages/home-page/photography-block";
import { repLinGradient } from "../components/utils";

const layoutWidth = 1280;
const layoutPadding = '1em';
const Wrapper = styled.div`
    /* > * + * {
        margin-top: 1em;
    } */
`;
const ContentBlock = styled.div`
    padding: 0 ${layoutPadding};
    max-width: ${layoutWidth}px;
    margin: 0 auto;
`;

export default function Gamedev() {
    return <DefaultLayout>
        <Wrapper>
            <div style={{ backgroundColor: '#fff', position: 'relative', boxShadow: '0 0 .25em rgba(0, 0, 0, .5)' }}>
                <ContentBlock>
                    <div style={{ padding: '3em 0' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <HelloBlock />
                        </div>
                    </div>
                </ContentBlock>
            </div>

            {/* <div style={{backgroundColor: '#fff'}}>
        <ContentBlock>
          <div style={{padding: '2em 0'}}>
            <TextBlock>
              <p>My best motivation to learn new programming stuff is to leverage it within my personal projects. The prospect of utilizing technologies to bring my own vision to life is what keeps me going.</p>

              <img src="/images/madfractal/devlog-6-thumbnail-bg.png" alt="" style={{width: 480, height: 270, float: 'right', marginLeft: '1em', marginBottom: '1em'}} />
              <img src="/images/madfractal/devlog-5-thumbnail-bg.png" alt="" style={{width: 480, height: 270, float: 'right', clear: 'right', marginTop: 0, marginLeft: '1em', marginBottom: '1em'}} />
              <p>My main side-project is a computer game called <strong>Madfractal</strong> which I've been working on since 2019 using the <strong>Unity</strong> engine. It's heavily inspired by the <strong>Diablo II</strong> game which I used to play a lot. I think the dark, gothic atmosphere of the game is what made me take a chance at implementing my own take on the action-RPG genre.</p>

              <img src="/images/madfractal/devlog-5-thumbnail-bg.png" alt="" style={{width: 480, height: 270, float: 'left', marginRight: '1em', marginBottom: '1em'}} />
              <p>You can visit <a href="https://madfractal.com" target="__blank">madfractal.com</a> and my gamedev Twitter channel <a href="https://twitter.com/LukGameDev" target="__blank">twitter.com/LukGameDev</a> for latest news.</p>
            </TextBlock>
          </div>
        </ContentBlock>
      </div> */}

            {/* <div style={{backgroundColor: '#bbb'}}>
        <ContentBlock>
          <div style={{padding: '2em 0'}}>
            <TextBlock>
              <p>I have a YouTube channel where I mainly post devlogs for the game among other gamedev-related experiments, sometimes in a form of music-videos, and other stuff like a few web-dev videos.</p>
            </TextBlock>
          </div>
        </ContentBlock>
      </div> */}

            <div style={{
              // backgroundColor: `hsl(240, 25%, 50%)`,
              background: repLinGradient(135, `hsl(240, 25%, 50%)`, `hsla(240, 25%, 50%, .95)`, '1em', '1.5em'),
              padding: '2em 0'
            }}>
                <ContentBlock>
                    <div style={{ backgroundColor: '#fff', padding: '2em', boxShadow: '0 0 .25em rgba(0, 0, 0, .5)' }}>
                        <GamedevBlock extended={true} />
                    </div>
                </ContentBlock>
            </div>
        </Wrapper>
    </DefaultLayout>
}
