import * as React from "react";
import styled from "@emotion/styled";

import * as Icons from '../../icons';

const widthBreakpoint = '1280px';
const Wrapper = styled.div`
    display: flex;
    /* justify-content: center; */
    align-items: center;

    @media screen and (min-width: ${widthBreakpoint}) {
        > * + * {
            margin-left: 2em;
        }
    }

    img {
        border-radius: 100%;
        display: block;
        max-width: 200px;
        max-height: 200px;
    }
`;
const PhotoBlock = styled.div`
    display: none;

    @media screen and (min-width: ${widthBreakpoint}) {
        display: block;
    }
`;
const InfoBlock = styled.div`
    > * + * {
        margin-top: 1em;
    }
`;
const TopInfo = styled.div`
    color: #aaa;
    line-height: 1.5;
`;
const SocialIconsList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    line-height: 1;

    li {
        display: flex;
        align-items: stretch;
    }

    > * + * {
        /* margin-left: 1em; */
    }

`;
const IconBlock = styled.a`
    display: block;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    max-width: 3em;

    > * + * {
        margin-top: .5em;
    }

    &:not(:hover) .social-icon-image {
        background-color: #fff !important;
    }

    &:hover .social-icon-image {
        svg {
            fill: #fff;
        }
    }
`;
const IconImage = styled.div`
    padding: .5em .5em;
    transition: background-color .2s;

    svg {
        fill: rgba(0, 0, 0, .15);
        display: block;
        transition: fill .2s;
    }
`;
const IconName = styled.div`
    color: rgba(0, 0, 0, .5);
    font-size: .35em;
    line-height: 1.4;
    display: block;
    text-align: center;
`;

export default function HelloBlock() {
    const social = [
        {
            name: 'Gamedev YouTube',
            Icon: Icons.YouTube,
            color: '#FF0000',
            url: 'https://www.youtube.com/channel/UCD4zBy-bxuTdt3UUMowjcaA',
        },
        {
            name: 'Gamedev Twitter',
            Icon: Icons.Twitter,
            color: '#1DA1F2',
            url: 'https://twitter.com/LukGameDev',
        },
        {
            name: 'Discord',
            Icon: Icons.Discord,
            color: '#7289DA',
            url: 'https://discord.gg/s536z5Y',
        },
        {
            name: 'Photography',
            Icon: Icons.Instagram,
            color: '#E4405F',
            url: 'https://www.instagram.com/_lukg_/',
        },
        {
            name: 'Graphic Art',
            Icon: Icons.Instagram,
            color: '#E4405F',
            url: 'https://www.instagram.com/lukg_creations/',
        },
        // {
        //     name: 'Imgur',
        //     Icon: Icons.Imgur,
        //     color: '#1BB76E',
        //     url: 'https://imgur.com/user/lukg',
        // },
        // {
        //     name: 'ArtStation',
        //     Icon: Icons.ArtStation,
        //     color: '#13AFF0',
        //     url: 'https://www.artstation.com/lukaszgrolik',
        // },
        {
            name: 'GitHub',
            Icon: Icons.GitHub,
            color: '#181717',
            url: 'https://github.com/lukaszgrolik',
        },
    ];

    return (
        <Wrapper>
            <PhotoBlock>
                {/* <img src="https://source.unsplash.com/random/240x240" /> */}
                <img src="/images/photo_edit1_200x200.JPG" />
            </PhotoBlock>

            <InfoBlock>
                <TopInfo>
                    <p>Hi, I'm Łukasz. <span>(Lucas or Luke)</span></p>
                    <p>I live in Katowice, Poland.</p>
                    <p>I do programming, music and graphic art.</p>
                </TopInfo>

                <div>
                    <SocialIconsList>
                        {
                            social.map(icon => {
                                return (
                                    <li key={icon.name}>
                                        <IconBlock href={icon.url} target="_blank">
                                            <IconImage className="social-icon-image" style={{backgroundColor: icon.color}}><icon.Icon /></IconImage>
                                            <IconName>{icon.name}</IconName>
                                        </IconBlock>
                                    </li>
                                )
                            })
                        }
                    </SocialIconsList>
                </div>
            </InfoBlock>
        </Wrapper>
    );
}
