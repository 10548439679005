import * as React from "react";
import styled from "@emotion/styled";

const layoutWidth = 1280;
const layoutPadding = '1em';
const ContentBlock = styled.div`
    padding: 0 ${layoutPadding};
    max-width: ${layoutWidth}px;
    margin: 0 auto;
`;
// @todo DRY
const TextBlock = styled.div`
    /* text-align: justify; */

    &:after {
        content: ".";
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }

    > * + * {
        margin-top: 1em;
    }

    p {
        color: rgba(0, 0, 0, .5);
        font-size: .6em;
    }

    a {
        border-bottom: 1px solid;

        &:hover {
            color: #fff;
            background-color: rgba(0, 0, 0, .5);
        }
    }

    strong {
        font-weight: bold;
    }
`;
const YouTubeVideoBlock = styled.div`
    background-color: #eee;
    margin-top: 1em;
    line-height: 1;

    iframe {
        display: block;
        margin: 0 auto;
    }
`;
const ImagesGrid = styled.div`
    display: grid;
    /* grid-template-rows: repeat(8, 5vw); */
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1em;

    img {
        display: block;
        width: 100%;
        height: 100%;
        /* width: 350px;
        height: 350px; */
        object-fit: cover;
    }
`;

const ImagesGroupsList = styled.ul`
    > * + * {
        margin-top: 2em;
    }
`;
const ImagesBlockWrapper = styled.div`
    > * + * {
        margin-top: 1em;
    }
`;
const VideoBlock = styled.div`
    background-color: #eee;

    video {
        max-width: 640px;
        margin: 0 auto;
        display: block;
    }
`;
const ImagesBlockHeader = styled.h3`
    color: rgba(0, 0, 0, .5);
    font-size: .75em;
    line-height: 1;
    padding-bottom: .25em;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
`;
const ContentWrapper = styled.div`
    color: rgba(0, 0, 0, .5);
    font-size: .6em;
    /* line-height: 1; */

    p {
        line-height: 1.5;
    }

    a {
        border-bottom: 1px solid;

        &:hover {
            color: #fff;
            background-color: rgba(0, 0, 0, .5);
        }
    }

    strong {
        font-weight: bold;
    }

    > * + * {
        margin-top: .5em;
    }
`;

function ImagesBlock(props: {imageGroup: ImageGroup}) {
    const {imageGroup} = props;

    return (
        <ImagesBlockWrapper>
            <ImagesBlockHeader>{imageGroup.title}</ImagesBlockHeader>

            {
                imageGroup.Content
                &&
                <ContentWrapper>
                    <imageGroup.Content />
                </ContentWrapper>
            }

            {
                imageGroup.gfycatId
                &&
                <VideoBlock>
                    <video
                        autoPlay={false}
                        playsInline={false}
                        preload="auto"
                        poster={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.jpg`}
                        tabIndex={-1}
                        // width={1600}
                        // height={900}
                        controls={true}
                    >
                        <source src={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.mp4`} type="video/mp4" />
                        <source src={`https://giant.gfycat.com/${imageGroup.gfycatId}.webm`} type="video/webm" />
                        <source src={`https://giant.gfycat.com/${imageGroup.gfycatId}.mp4`} type="video/mp4" />
                        <source src={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.mp4`} type="video/mp4" />
                    </video>
                </VideoBlock>
            }

            <ImagesGrid>
                {
                    imageGroup.paths.map((imgPath, i) => {
                        return (
                            <div key={imgPath}>
                                <img
                                    loading="lazy"
                                    src={`${imageGroup.basePath}${imgPath}`}
                                    width={300}
                                    height={300}
                                />
                            </div>
                        );
                    })
                }
            </ImagesGrid>
        </ImagesBlockWrapper>
    );
}

const madfractalScreenshots = [
    "vlcsnap-2021-01-20-23h13m18s110_square.png",
    "vlcsnap-2021-01-20-23h14m49s967_square.png",
    "vlcsnap-2021-01-24-23h22m30s825_square.png",
    "screenshot_3840x2160_2020-07-19_12-33-08_square.png",
    "screenshot_3840x2160_2020-07-19_12-49-45_square.png",
    "screenshot_3840x2160_2020-07-19_12-46-12_square.png",
    "screenshot_3840x2160_2020-07-19_12-42-38_square.png",
    'church 2_square.png',
    'devlog-6-thumbnail-bg_square.png',
    'devlog-5-thumbnail-bg_square.png',
    'devlog-2-thumbnail-bg_square.png',
];
const economyRTSScreenshots = [
    "screenshot_2021-03-24_13-47-41_1920x1080_square.png",
    "screenshot_2021-03-17_21-53-19_1920x1080_square.png",
    "screenshot_2021-03-17_17-03-20_1920x1080_square.png",
    "screenshot_2021-03-17_13-31-56_1920x1080_square.png",
];
const imagesProject = [
    'city-builder_square.png',
    // 'city-builder-timelapse.gif',
    'forest at the beach 1 screen_2560x1440_2019-06-06_21-45-38_square.png',
    'screenshot-2019-04-18-103128813_square.png',
    'screenshot-2019-04-18-103424481_square.png',
];

const imageGroups: ImageGroup[] = [
    {
        title: "Madfractal",
        basePath: '/images/madfractal/',
        paths: madfractalScreenshots,
        // gfycatId: 'PessimisticSarcasticAnt',
        gfycatId: 'ShamefulBlissfulIcelandichorse',
        Content: () => {
            return (
                <>
                    <p>My main side-project is a computer game called <strong>Madfractal</strong> which I've been working on since 2019 using the <strong>Unity</strong> engine. It's heavily inspired by the <strong>Diablo II</strong> game which I used to play a lot. I think the dark, gothic atmosphere of the game is what made me take a chance at implementing my own take on the action-RPG genre.</p>

                    <p>You can visit <a href="https://madfractal.com" target="__blank">madfractal.com</a> and my gamedev Twitter channel <a href="https://twitter.com/LukGameDev" target="__blank">@LukGameDev</a> for latest news.</p>
                </>
            );
        },
    },
    {
        title: "Untitled economy RTS game",
        basePath: '/images/economy-rts/',
        paths: economyRTSScreenshots,
        // gfycatId: 'PessimisticSarcasticAnt',
        gfycatId: 'CautiousAllColt',
        Content: () => {
            return (
                <>
                    <p>I've also been working on an economy strategy game inspired by the late 1990s and early 2000s Sierra games like <strong>Caesar</strong>, <strong>Pharaoh</strong> and <strong>Zeus</strong>, using hand-drawn graphics style.</p>
                </>
            );
        },
    },
    {
        title: "Unity side-projects & experiments",
        basePath: '/images/projects/',
        paths: imagesProject,
    },
];

interface ImageGroup {
    title: string;
    basePath: string;
    paths: string[];
    gfycatId?: string;
    Content?: () => JSX.Element;
}

export default function GamedevBlock(props: { extended: boolean; content?: React.FC }) {
    const { content: Content } = props;

    return (
        <div>
            <div style={{  }}>
                <TextBlock>
                    <h2>Gamedev</h2>

                    <p>I have a <a href="https://www.youtube.com/channel/UCD4zBy-bxuTdt3UUMowjcaA">YouTube channel</a> where I mainly post devlogs among other gamedev-related experiments.</p>
                </TextBlock>

                <YouTubeVideoBlock>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube-nocookie.com/embed/wPkDTSG733Y"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </YouTubeVideoBlock>

                {
                    Content
                    &&
                    <TextBlock style={{ marginTop: '1em' }}>
                        <Content />
                    </TextBlock>
                }

                {
                    props.extended
                    &&
                    <>
                        <div style={{ marginTop: '2em' }}>
                            <ImagesGroupsList>
                                {
                                    imageGroups.map(imageGroup => {
                                        return (
                                            <li key={imageGroup.title}>
                                                <ImagesBlock imageGroup={imageGroup} />
                                            </li>
                                        );
                                    })
                                }
                            </ImagesGroupsList>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}
